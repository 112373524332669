@import '@/_styles/import';
// Use "--share-button-outline-color" css variable on the parent to set outline color

.ShareButton {
  $root: &;

  &__opener {
    cursor: pointer;

    width: 40px;
    height: 40px;
    padding: 0;

    line-height: 40px;
    color: $gray-light;

    background-color: $white;
    border: 0;
    border-radius: 50%;
    box-shadow: 0 0.125rem 0.25rem $black-003;

    &:hover {
      background-color: $vv-red;

      .Icon.SocialShare {
        fill: $white;
      }
    }

    &:disabled {
      cursor: default;
    }

    #{$root}._open & {
      background-color: $vv-red;
    }

    .Icon.SocialShare {
      display: flex;
      align-items: center;
      justify-content: center;

      width: inherit;
      height: inherit;

      fill: $vv-red;

      #{$root}._open & {
        fill: $white;
      }

      svg {
        display: block;
        width: 18px;
        height: 18px;
      }
    }

    &:disabled .Icon.SocialShare {
      pointer-events: none;
      cursor: pointer;
      opacity: 0.5;
    }

    &:focus,
    &:focus-visible {
      outline: 2px solid var(--share-button-outline-color, $black);
      outline-offset: 1px;
    }
  }

  &__modal {
    z-index: $z-index-share-button-modal;
    animation: fadeIn ease-out 0.35s;
  }

  &__balloon {
    --target-size: #{rem(40)};

    padding: rem(20) rem(22);
  }

  .ShareLinkSet__copy-status {
    bottom: #{rem(-66)};
  }
}
